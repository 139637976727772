<template>
    <div class="com-header">
        <div class="main">
            <img
                src="../assets/header/logo.png"
                alt=""
                class="logo"
            >
            <div class="icons">
                <i class="el-icon-service icons1"></i>
                <i class="el-icon-search icons2"></i>
            </div>
            <nav class="nav-list">
                <router-link
                    v-for="item in navList"
                    :key="item.id"
                    :to="item.path"
                >{{item.name}}</router-link>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "comHeader",
    props: {},
    data() {
        return {
            navList: [
                { id: 1, name: "首页", path: "/Home" },
                { id: 2, name: "走进艺霏", path: "/Brand" },
                { id: 3, name: "所有产品", path: "/Products" },
                { id: 4, name: "新闻动态", path: "/News" },
                { id: 5, name: "服务中心", path: "/Services" },
                { id: 6, name: "防伪查询", path: "/Antifake" },
            ],
        };
    },
};
</script>

<style scoped>
.com-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 135px;
    background-color: #fff;
    z-index: 999;
    margin-bottom: 135px;
}
.main {
    position: relative;
    width: 1100px;
    height: 135px;
    overflow: hidden;
    margin: 0 auto;
}
.main .logo {
    display: block;
    width: 238px;
    height: 60px;
    margin: 20px auto;
}
.icons {
    position: absolute;
    top: 38px;
    right: 0;
    font-size: 16px;
}
.icons .icons1 {
    margin-right: 20px;
}
.nav-list {
    width: 1100px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
}
.router-link-active {
    color: #faa878;
}
</style>