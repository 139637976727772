import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/Home",
    name: "home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/brand",
    name: "brand",
    component: () =>
      import(/* webpackChunkName: "brand" */ "../views/Brand.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import(/* webpackChunkName: "news" */ "../views/News.vue"),
  },
  {
    path: "/gsdtNews/:newsId",
    name: "gsdtNews",
    component: () =>
      import(/* webpackChunkName: "gsdtNews" */ "../views/gsdtNews.vue"),
  },
  {
    path: "/schdNews/:newsId",
    name: "schdNews",
    component: () =>
      import(/* webpackChunkName: "schdNews" */ "../views/schdNews.vue"),
  },
  {
    path: "/xpkxNews/:newsId",
    name: "xpkxNews",
    component: () =>
      import(/* webpackChunkName: "xpkxNews" */ "../views/xpkxNews.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue"),
  },
  {
    path: "/antifake",
    name: "antifake",
    component: () =>
      import(/* webpackChunkName: "Antifake" */ "../views/Antifake.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
});

export default router;
