<template>
    <div id="app">
        <commonHeader />
        <router-view />
        <commonFooter />
    </div>
</template>
<script>
import commonHeader from "@/components/commonHeader.vue";
import commonFooter from "@/components/commonFooter.vue";
export default {
    name: "app",
    props: {},
    components: { commonHeader, commonFooter },
    data() {
        return {};
    },
};
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #fff;
    font-size: 12px;
    padding-top: 135px;
}
body {
    margin: 0;
}
a {
    text-decoration: none;
    color: #000;
}
</style>
