<template>
    <div class="com-footer">
        <nav class="text-link">
            <router-link
                v-for="item in linkList"
                :key="item.id"
                :to="item.path"
            >{{item.name}}</router-link>
        </nav>
        <div class="other">
            <div class="text-box"><i class="el-icon-chat-dot-round message"></i><span>在线咨询</span> </div>
            <div class="text-box"><i class="el-icon-phone phone"></i><span>021-64149631</span> </div>
            <div @click="outLink('wx')" class="btnBoxItem1">
                <img
                    src="../assets/footer/wx.png"
                    alt=""
                >
            </div>
            <div @click="outLink('wb')" class="btnBoxItem1">
                <img
                    src="../assets/footer/wb.png"
                    alt=""
                >
            </div>
            <div @click="outLink('xhs')" class="btnBoxItem1">
                <img
                    src="../assets/footer/xhs.png"
                    alt=""
                >
            </div>
            <div @click="outLink('tm')" class="btnBoxItem1">
                <img
                    src="../assets/footer/tm.png"
                    alt=""
                >
            </div>
            <div @click="outLink('jd')" class="btnBoxItem1">
                <img
                    src="../assets/footer/jd.png"
                    alt=""
                >
            </div>
            <div class="text-box " >
              <span @click="outLink('ba')"  class="btnBoxItem">沪ICP备2022030058号-1</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "comFooter",
    props: {},
    data() {
        return {
            linkUrl: {
                wx: "https://weixin.qq.com/",
                wb: "https://weibo.com/",
                xhs: "https://www.xiaohongshu.com/",
                tm: "https://www.tmall.com/",
                jd: "https://www.jd.com/",
                ba: "https://beian.miit.gov.cn/#/Integrated/index",
            },
            linkList: [
                { id: 1, name: "联系我们", path: "/Services" },
                { id: 2, name: "关注我们", path: "/Services" },
                { id: 3, name: "官方店铺导航", path: "www.baidu.com" },
            ],
        };
    },
    methods: {
        outLink(key) {
            window.open(this.linkUrl[key], "_blank");
        },
    },
};
</script>

<style scoped>
.com-footer {
    width: 100%;
    height: 400px;
    color: #fff;
    background-color: #342c2a;
    overflow: hidden;
    margin-top: 116px;
}
.text-link {
    width: 1100px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    margin: 90px auto 135px auto;
}
.text-link a {
    color: #fff;
    font-weight: 400;
}
.other {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}
.other .message {
    font-size: 35px;
}
.other .phone {
    font-size: 35px;
}
.other .text-box {
    font-size: 18px;
    display: flex;
    align-items: center;
}
.other .btnBoxItem:hover {
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.other .btnBoxItem1:hover {
  cursor: pointer;
}
</style>
