<template>
    <div class="HomePage">
        <!-- <el-carousel height="750px">
            <el-carousel-item
                v-for="item in bannerList"
                :key="item.id"
            >
                <img
                    :src="item.url"
                    alt=""
                >
            </el-carousel-item>
        </el-carousel> -->
        <el-carousel height="750px">
            <el-carousel-item>
                <img
                    src="../assets/home/banner1.png"
                    alt=""
                >
            </el-carousel-item>
            <el-carousel-item>
                <img
                    src="../assets/home/banner1.png"
                    alt=""
                >
            </el-carousel-item>
            <el-carousel-item>
                <img
                    src="../assets/home/banner1.png"
                    alt=""
                >
            </el-carousel-item>
            <el-carousel-item>
                <img
                    src="../assets/home/banner1.png"
                    alt=""
                >
            </el-carousel-item>
        </el-carousel>
        <div class="main">
            <div class="instruct">
                <img
                    class="title"
                    src="../assets/home/gsjs.png"
                    alt=""
                >
                <div class="text">
                    上海一非医药科技有限公司是一家是有着医学背 <br /> 景的功效型护肤品研发、生产、销售企业。<br />
                    公司致力于巴布剂、双向纳米胶束技术等经皮吸收技术研究，<br />聚焦美白淡斑领域，打造出“艺霏”品牌系列产品。
                </div>
                <img
                    src="../assets/home/cf.png"
                    alt=""
                >
            </div>
            <div class="star">
                <img
                    class="title"
                    src="../assets/home/mxdp.png"
                    alt=""
                >
                <!-- <div class="item-product-box">
                    <div class="item-product-instr">
                        <div class="name">虾青素皮肤屏障修复液</div>
                        <div class="efficacy"> 修复屏障，改善敏感</div>
                        <div class="color-box"></div>
                        <div class="find-more"> 查看更多</div>
                        <img
                            class="number-img"
                            src="../assets/home/number1.png"
                            alt=""
                        >
                    </div>
                    <img
                        class="item-product-img"
                        src="../assets/home/p1.jpg"
                        alt=""
                    >
                </div> -->
                <div class="item-product-box">
                    <img
                        class="item-product-img"
                        src="../assets/home/p2.jpg"
                        alt=""
                    >
                    <div class="item-product-instr">
                        <img
                            class="number-img"
                            src="../assets/home/number2.png"
                            alt=""
                        >
                        <div class="name">深透雪颜敷贴</div>
                        <div class="efficacy"> 加乘吸收，肌源耀白</div>
                        <div class="color-box"></div>
                        <div class="find-more"> 查看更多</div>
                    </div>
                </div>
                <div class="item-product-box">
                    <div class="item-product-instr">
                        <div class="name">玉容巴布贴</div>
                        <div class="efficacy"> 滋养焕活，实力抑黑</div>
                        <div class="color-box"></div>
                        <div class="find-more"> 查看更多</div>
                        <img
                            class="number-img"
                            src="../assets/home/number3.png"
                            alt=""
                        >
                    </div>
                    <img
                        class="item-product-img"
                        src="../assets/home/p3.png"
                        alt=""
                    >
                </div>
                <div class="item-product-box">
                    <img
                        class="item-product-img"
                        src="../assets/home/p4.jpg"
                        alt=""
                    >
                    <div class="item-product-instr">
                        <img
                            class="number-img"
                            src="../assets/home/number4.png"
                            alt=""
                        >
                        <div class="name">虾青素蓓润修护精华液</div>
                        <div class="efficacy"> 修「氧」双效，焕启质变</div>
                        <div class="color-box"></div>
                        <div class="find-more"> 查看更多</div>
                    </div>
                </div>
                <div class="item-product-box">
                    <div class="item-product-instr">
                        <div class="name">传明酸烟酰胺精华液</div>
                        <div class="efficacy"> 多链路抑黑 全脸通透焕靓</div>
                        <div class="color-box"></div>
                        <div class="find-more"> 查看更多</div>
                        <img
                            class="number-img"
                            src="../assets/home/number5.png"
                            alt=""
                        >
                    </div>
                    <img
                        class="item-product-img"
                        src="../assets/home/p5.jpg"
                        alt=""
                    >
                </div>
                <div class="item-product-box">
                    <img
                        class="item-product-img"
                        src="../assets/home/p6.jpg"
                        alt=""
                    >
                    <div class="item-product-instr">
                        <img
                            class="number-img"
                            src="../assets/home/number6.png"
                            alt=""
                        >
                        <div class="name">传明酸巴布贴</div>
                        <div class="efficacy"> 一呼·一吸，光感匀净</div>
                        <div class="color-box"></div>
                        <div class="find-more"> 查看更多</div>
                    </div>

                </div>
            </div>

            <div>
                <video-player
                    class="vjs-custom-skin"
                    :options="playerOptions"
                    ref="videoPlayer"
                ></video-player>
            </div>
        </div>

    </div>
</template>
<script>
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
export default {
    name: "HomePage",
    props: {},
    data() {
        return {
            // bannerList: [{ id: 1, url: "../assets/home/banner1.png" }],
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: "https://pfgj-1309368755.cos.ap-shanghai.myqcloud.com/yifei/companyIntroduction.mp4", // url地址
                    },
                ],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true, // 是否显示全屏按钮
                },
            },
        };
    },
};
</script>

<style scoped>
.main {
    width: 1100px;
    margin: 0 auto;
}
.instruct .title {
    display: block;
    margin: 75px auto 45px auto;
}
.instruct .text {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    margin-bottom: 55px;
}
.star .title {
    display: block;
    margin: 75px auto 45px auto;
}
.item-product-box {
    display: flex;
    margin-bottom: 80px;
}
.item-product-box .item-product-instr {
    position: relative;
    width: 550px;
    padding-left: 15px;
}
.item-product-box .item-product-instr .name {
    font-size: 44px;
    font-weight: bold;
    letter-spacing: 4px;
    margin-top: 50px;
}
.item-product-box .item-product-instr .efficacy {
    font-size: 30px;
    letter-spacing: 4px;
    margin-top: 30px;
}
.item-product-box .item-product-instr .number-img {
    position: absolute;
    right: 35px;
    bottom: 50px;
}
.item-product-box .item-product-instr .color-box {
    width: 178px;
    height: 9px;
    background-color: #faa878;
    margin: 28px 0;
}
.item-product-box .item-product-instr .find-more {
    font-size: 22px;
    color: #faa878;
    letter-spacing: 4px;
    cursor: pointer;
}
.item-product-img {
    width: 550px;
    height: 400px;
}
</style>